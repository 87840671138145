import { Navbar } from "react-bootstrap";
import { useNavigate } from "react-router-dom";  // Updated import
import { Link } from "react-router-dom";
import logo from '../assets/apple.svg';

function NavBar() {
    const navigate = useNavigate();  // Updated to useNavigate

    return (
        <Navbar className="navbar navbar-light bg-light" expand="md">
            <div className="container">
                <a className="navbar-brand" href="#" onClick={(e) => { e.preventDefault(); navigate('/'); }}>
                    <img src={logo} alt="Apple" width="30" height="24" className="d-inline-block align-text-top"></img>
                    Mosterei Kiefer
                </a>
            
                {/*<Navbar.Toggle aria-controls="responsive-navbar-nav" />*/}
                <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
                    <ul className="navbar-nav">
                        <li className="nav-item">
                            <a className="nav-link" href="#" onClick={(e) => { e.preventDefault(); navigate('/'); }}>                 
                                Home                        
                            </a>
                        </li>
                    </ul>           
                </Navbar.Collapse>
            </div>
        </Navbar>
    );
}

export default NavBar;
