
import { Card } from "react-bootstrap"
import Apple from "../assets/appleBackground.webp"
import Footer from "../components/footer";
import { Button } from "react-bootstrap";

import {MdEmail, MdPhone, MdMap} from "react-icons/md";
import { useEffect } from "react";
import Slider from "react-slick";

import colors from "../style/theme.module.scss"


import BoxSquare from "../assets/box_square.webp"
import TrottenSquare from "../assets/trotten_square.webp"
import AppleSquare from "../assets/apfelsaft.webp"

import ProcessLG from "../assets/process_lg.webp"
import TrotteLG from "../assets/trotte_lg.webp"
import ApfelsaftLG from "../assets/apfelsaft_lg.webp"

import Gallery1 from "../assets/gallery1.webp"
import Gallery2 from "../assets/gallery2.webp"
import Gallery3 from "../assets/gallery3.webp"
import Gallery4 from "../assets/gallery4.webp"
import Gallery5 from "../assets/gallery5.webp"
import Gallery6 from "../assets/gallery6.webp"
import Gallery7 from "../assets/gallery7.webp"

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import StartImage from "../assets/start_grafik.webp"
import { useNavigate, useLocation, useParams } from 'react-router-dom';


function Home(props){

    const navigate = useNavigate();
    const location = useLocation();
    const params = useParams();

    const imageList = [Gallery1,Gallery2,Gallery3,Gallery4,Gallery5,Gallery6,Gallery7]
    var settings = {
        dots: true,
        infinite: true,
        speed: 300,     
        adjustHeight:true,
        centerMode:false,
        variableWidth: true,
        responsive: [
            {
                breakpoint: 500,
                settings: {
              
                    variableWidth: false,
                    adjustHeight:true,
                    rows:1
                }}
            ]             

      };

    return (
      
        <div className="">
             <div className="container-fluid pb-4 pt-4 bg-secondary">
                <div className="row">
                    <div className="col-12 d-flex justify-content-center">
                        <h5 className="mb-0">Die Apfelsaison hat begonnen.</h5>
                    </div>
                </div>
            </div>
            <div className="d-flex flex-column justify-content-center pb-0 mb-0 align-items-center background-heading-image"  style={{backgroundImage:`url(${Apple})`}} >
               
           
                <div className= "d-none d-lg-block  "  >
                   
                    <div className="row h-100 mt-5 mb-5 pb-5 pt-5  align-items-center">
                        <div className="col-12  d-flex align-items-center ">
                            <div className="container ">
                                <div className="row   ">
                                    <div className="col-12 ">
                                    
                                        <div className= "card text-center pb-5 pt-5 px-3 pl-5 justify-content-center d-flex flex-row bg-white ">
                                        <div className="align-self-center">
                                                 <h1 className="text-uppercase mb-4" style={{fontSize:"50px"}}>Mosterei Kiefer</h1>
                                                <h1 className="jumbotron-heading text-left text-dark  ">Pressen und Abfüllen von eigenen Äpfeln </h1>
                                                
                                                <p className="lead text-dark text-left">Seit 5 Generationen familiengeführt</p>
                                                <div className="mt-2 d-flex justify-content-center">
                                                    <a href="#Overview" className="btn btn-light mx-2">Übersicht</a>
                                                    <a href="#Kontakt" className="btn btn-secondary mx-2">Kontakt</a>
                                                </div>
                                        </div>
                                       
                                      {/*<Icon style={{fill:"#F0AB20",strokeWidth:"0px",maxWidth:"500px" }} className="pr-3 col-6"></Icon>*/}
                                      <img src={StartImage} style={{maxWidth:"500px" }} ></img>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="pb-5 pt-5 d-lg-none d-block"  >
                
                    <div className="row h-100  mb-5  align-items-center">
                        <div className="col-12  d-flex align-items-center ">
                            <div className="container ">
                                <div className="row   ">
                                    <div className="col-12 ">
                                        <div className="card text-center pb-5 pt-5 px-3 ">
                                            <h1 className="text-uppercase mb-4" style={{fontSize:"35px"}}>Mosterei Kiefer</h1>
                                            <h3 className="text-dark">Pressen und Abfüllen von Ihren Äpfeln </h3>
                                            <p className="lead text-muted">Seit 5 Generationen familiengeführt</p>
                                            <div className="mt-2">
                                                <a href="#Overview" className="btn btn-light mx-2">Übersicht</a>
                                                <a href="#Kontakt" className="btn btn-secondary mx-2">Kontakt</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            {/*
            <div className="container-fluid pb-4 pt-4 bg-primary">
                <div className="row">
                    <div className="col-12 d-flex justify-content-center">
                        <h5 className="mb-0">Die Apfelsaison hat noch nicht begonnen</h5>
                    </div>
                </div>
            </div>
            */}
            
           
            

            
            <div className="container-fluid pt-5 pb-5">
                
                <div className="row ">
                    
                    <div className="col-12 mb-2" id="unsereLeistung">
                        <h3 className="text-center mb-0  pb-0 text-dark text-uppercase">Unsere Leistungen</h3>
                        <div className="w-100 d-flex justify-content-center">
                        <div className="text-center mb-0 mt-1 pb-0" style={{width:"100px", color: colors.primary,backgroundColor: colors.primary, height: 3 }} >
                        </div>
                        </div>
                    </div>
                   
                    
                    <div className="col-12 row mb-2 ">
                        <div className="col-12  col-md-4 d-flex flex-column  align-items-center justify-content-start mt-4 ">
                            <img src={TrottenSquare} width="200px" style={{borderRadius: "50%"}}></img>
                            <h5 className="text-center mt-1">Ihre eigenen Äpfel pressen</h5>
                        </div>
                        <div className="col-12 col-md-4 d-flex flex-column  align-items-center justify-content-start mt-4 ">                            
                            <img src={BoxSquare} width="200px" style={{borderRadius: "50%"}}></img>
                            <h5 className="text-center mt-1 ">abfüllen von Apfelsaft in 5l & 10l Bag-In-Box</h5>
                        </div>
                        <div className="col-12 col-md-4 d-flex flex-column align-items-center justify-content-start mt-4">
                            <img src={AppleSquare} width="200px" style={{borderRadius: "50%"}}></img>
                            <h5 className="text-center mt-1">Verkauf von Most und Apfelsaft</h5>
                        </div>
                    </div>
                    
                </div>
            </div>
           
            <div className="container-fluid bg-light pt-5 pb-5">

                <div className="row ">
                    <div className="col-12 mb-3" id="Overview">
                        <h3 className="text-center mb-0  pb-0 text-dark text-uppercase">Äpfel pressen</h3>
                        <div className="w-100 d-flex justify-content-center">
                        <div className="text-center mb-0 mt-1 pb-0" style={{width:"100px", color: colors.primary,backgroundColor: colors.primary, height: 3 }}>
                        </div>
                        </div>
                    </div>
                    <div className="col-md-6 mt-2 d-flex justify-content-md-end justify-content-center">
                        <img src={TrotteLG} className="mx-2" width="100%" style={{"maxWidth":"500px"}}></img>
                     
                    </div>
                    <div className="col-md-6 mt-3">
                        <div className="d-flex mx-2 flex-column h-100 justify-content-center align-items-md-ceenter align-items-center ">
                            <h5 className="text-left">Frisch gepresster Apfelsaft</h5>
                            <ul>
                            <li>
                                <p className="text-left mb-0">
                                    Müheloses abladen, Bodeneben
                                </p>
                            </li>
                            <li>
                                <p className="text-left mb-0">
                                    Hohe Ausbringungsquote
                                </p>
                            </li>
                            <li>
                                <p className="text-left mb-0">
                                    pressen Ihrer eigenen Äpfel
                                </p>
                            </li>                          

                        </ul>


                        </div>
                    </div>
                </div>
                <div className="row mt-5 ">
                    <div className="col-12 mb-3" >
                        <h3 className="text-center  pb-0 text-dark text-uppercase">Apfelsaft abfüllen</h3>
                        <div className="w-100 d-flex justify-content-center">
                        <div className="text-center mb-0 mt-1 pb-0" style={{width:"100px", color: colors.primary,backgroundColor: colors.primary, height: 3 }}>
                        </div>
                        </div>
                    </div>
                </div>

                <div className="row d-flex d-block-md flex-column-reverse flex-md-row ">
                   
                    <div className="col-md-6 mt-3 ">
                        <div className="d-flex  mx-2 flex-column h-100 justify-content-center align-items-md-center align-items-center ">
                            <h5 className="text-left ">Abfüllen in 5l & 10l Boxen</h5>
                            <ul>
                            <li>
                                <p className="text-left mb-0">
                                    Karton wiederverwendbar
                                </p>
                            </li>
                            <li>
                                <p className="text-left mb-0">
                                    Lange Halbarkeit
                                </p>
                            </li>
                            <li>
                                <p className="text-left mb-0">
                                    Naturtrüb, gefiltert und ohne Zusätze wie etwa Zucker
                                </p>
                            </li>                          

                        </ul>
                        <div className="d-flex justify-content-end w-100">
                            <button onClick={()=>navigate("bag-in-box")} className="btn btn-sm btn-primary">Mehr Informationen</button>
                        </div>


                        </div>
                    </div>
                    <div className="col-md-6 mt-2 d-flex justify-content-md-start justify-content-center">
                        <img src={ProcessLG} className="mx-2" width="100%" style={{"maxWidth":"500px"}}></img>
                     
                    </div>
                    
                </div>

                <div className="row mt-5 mb-5">
                    <div className="col-12 mb-3" >
                        <h3 className="text-center mb-0  pb-0 text-dark text-uppercase">Most kaufen</h3>
                        <div className="w-100 d-flex justify-content-center">
                        <div className="text-center mb-0 mt-1 pb-0" style={{width:"100px", color: colors.primary,backgroundColor: colors.primary, height: 3 }}>
                        </div>
                        </div>
                    </div>
                    <div className="col-md-6 mt-2 d-flex justify-content-md-end justify-content-center">
                        <img src={ApfelsaftLG} className="mx-2" width="100%" style={{"maxWidth":"500px"}}></img>
                     
                    </div>
                    <div className="col-md-6 mt-3">
                        <div className="d-flex mx-2 flex-column h-100 justify-content-center align-items-md-ceenter align-items-center ">
                            <h5 className="text-left">Wir bieten Most von unseren eigenen Äpfelen</h5>
                            <ul>
                            <li>
                                <p className="text-left mb-0">
                                    Nach Anfrage können Sie den Most und abgefüllten Apfelsaft in beliebiger Menge abholen.
                                </p>
                            </li>
                            
                        </ul>


                        </div>
                    </div>
                </div>


            </div>

            <div className="container-fluid bg-white pt-5 pb-5">
              
                <div className="row ">
                    <div className="col-12 mb-2" >
                        <h3 className="text-center mb-0  pb-0 text-dark text-uppercase">WEITERE EINDRÜCKE VON UNSERER PRODUKTION</h3>
                        <div className="w-100 d-flex justify-content-center">
                        <div className="text-center mb-0 mt-1 pb-0" style={{width:"100px", color: colors.primary,backgroundColor: colors.primary, height: 3 }}>
                        </div>
                        </div>
                    </div>
                    <div className="">
                    <Slider {...settings} >
                        {imageList.map(image =>{

                            return <div className=""> <img src={image} className="" style={{maxHeight:"300px"}}   ></img></div>

                        })}
                    </Slider>
                    </div>
                </div>



            </div>

            <div className="container-fluid bg-light pt-5 pb-5">
              
              <div className="row ">
                  <div className="col-12 mb-2" >
                      <h3 className="text-center mb-0  pb-0 text-dark text-uppercase" id="Kontakt">Anmeldung und Kontakt</h3>
                      <div className="w-100 d-flex justify-content-center">
                      <div className="text-center mb-0 mt-1 pb-0" style={{width:"100px", color: colors.primary,backgroundColor: colors.primary, height: 3 }}>
                      </div>
                      </div>
                  </div>
              </div>
              <div className="row ">
                    <div className="col-12 text-center mt-5">
                        <h4>
                        Anmeldung erforderlich
                        </h4>
                        <p>
                    Bitte vereinbaren Sie unbedingt einen Termin mit uns und geben Sie dabei die grob geschätzte Obstmenge an, damit wir den Zeitbedarf planen können.</p>
                    </div>
                    <div className="col-12">
                        
                        <h5 className="text-dark text-center">
                            <MdPhone className="text-dark mx-2"></MdPhone> 07805 3360
                        </h5>
                        <h5 className="text-dark text-center">
                            <MdMap className="text-dark mx-2"></MdMap> Hautpstraße 60,
                            77767 Urloffen
                        </h5>
                        </div>
                </div>

          </div>

                <div className="google-map-code">
                    <iframe src="https://www.google.com/maps/embed/v1/place?key=AIzaSyBEC1DwNAk0pZkX6kzixzQSpDEAafEWxAU&q=Hauptstrasse+60,+77767+Appenweier" width="100%" height="450" frameborder="0" style={{border:0}} allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
                    </div>
                


            <Footer></Footer>
        </div>

    )

};

export default Home;