import React from "react";
import { BrowserRouter, Routes, Route, Navigate, useNavigate } from "react-router-dom";
import logo from '../assets/apple.svg'
import NoMatch from "../views/NoMatch";
import Home from "../views/Home";
import {Navbar, Nav, Button} from "react-bootstrap"
import DataProtection from "../views/DataProtection";
import Impressum from "../views/Impressum"
import NavBar from "../components/navbar";
import BagInBox from "../views/BagInBox";

/**
 * routing
 */
function RoutesApp() {      
    
    return (
        <div className="">
            <BrowserRouter>
                <NavBar />
                <Routes>
                    <Route path="/" element={<Home />} />  
                    <Route path="/impressum" element={<Impressum />} />               
                    <Route path="/datenschutz" element={<DataProtection />} />    
                    <Route path="/bag-in-box" element={<BagInBox />} />
                    <Route path="*" element={<NoMatch />} />
                </Routes>
            </BrowserRouter>
        </div>
    );
}

export default RoutesApp;
